
import { defineComponent, PropType } from "vue";
import AppTabsHead from "@/components/AppTabs/AppTabsHead.vue";

export default defineComponent({
  name: "TableToolBar",
  components: { AppTabsHead },
  props: {
    tabs: { type: Array as PropType<string[]>, default: () => [] },
    parentSelectedTabId: { type: Number, default: 1 },
    initialSelectedTab: { type: Number, default: 0 }
  },
  emits: ["changeTab"],
  data() {
    return {
      selectedTabIdx: this.initialSelectedTab
    };
  },

  watch: {
    selectedTabIdx(idx: number) {
      this.$emit("changeTab", idx);
    },

    initialSelectedTab(idx: number) {
      if (idx !== this.selectedTabIdx) {
        this.selectedTabIdx = idx;
      }
    }
  }
});
