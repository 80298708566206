<template>
  <label
    :for="id"
    :class="{
      'app-switch': true,
      checked: modelValue
    }"
  >
    <input
      v-bind="$attrs"
      :id="id"
      type="checkbox"
      :checked="modelValue"
      @change="changeHandler"
    />
    <span class="app-switch-road">
      <span class="app-switch-control"></span>
    </span>
  </label>
</template>

<script>
export default {
  name: "AppSwitch",
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    modelValue: {
      type: [String, Boolean],
      default: ""
    }
  },

  emits: ["update:modelValue", "change"],

  methods: {
    changeHandler({ target }) {
      this.$emit("update:modelValue", target.checked);
      this.$emit("change", target.checked);
    }
  }
};
</script>

<style lang="scss">
$height: $switch-height;
$width: $switch-width;
:root {
  --switch-transition-duration: 0.2s;
}

.app-switch {
  position: relative;
  overflow: hidden;
  transition: var(--switch-transition-duration);
  input {
    position: absolute;
    top: -100%;
    visibility: hidden;
    pointer-events: none;
    &:disabled {
      & + * {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  &-road {
    height: $height;
    width: $width;
    background-color: $border-color;
    display: block;
    border-radius: 100px;
    position: relative;
    transition: inherit;
    cursor: pointer;
  }

  &-control {
    width: 50%;
    height: $height * 0.8;
    display: block;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(0, -50%);
    transition: inherit;
  }

  &.checked {
    .app-switch-road {
      background-color: $color-success;
    }

    .app-switch-control {
      transform: translate(calc(#{$width - $width / 2} - 4px), -50%);
    }
  }
}
</style>
