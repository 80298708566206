import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "filer-uploader" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "app-uploader-preview"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "app-uploader-placeholder"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      id: _ctx.id,
      type: "file",
      class: "app-uploader"
    }, _ctx.$attrs, {
      hidden: "",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadHandler && _ctx.uploadHandler(...args)))
    }), null, 16, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "upload-trigger"
    }, [
      (_ctx.renderedPreview)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.renderedPreview,
              alt: "alt"
            }, null, 8, _hoisted_5)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_6, [
            (_ctx.placeholder)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.placeholder,
                  alt: ""
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ])),
      _createVNode(_component_svg_icon, {
        icon: "camera",
        class: "camera"
      })
    ], 8, _hoisted_3)
  ]))
}