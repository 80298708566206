
import CreateDeviceDialog from "@/components/ui/Modal/Dialog/instances/CreateDeviceDialog.vue";
import DeleteDeviceDialog from "@/components/ui/Modal/Dialog/instances/DeleteDeviceDialog.vue";
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/runtime-core";
import DevicesTable from "@/components/Table/ts/instances/devices/DevicesTable.vue";
import { useStore } from "vuex";
import { devicesSetup } from "../interfaces/devicesInterface";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { sortOrders } from "@/enums/main/sortOrders";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
import { useSuportedDevices } from "@/hooks/devices/useDevices";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";

interface brandData {
  id: number;
  selected: boolean;
  name: string;
}

interface editDeviceData {
  title: string;
  active: boolean;
  brand?: string;
  icon?: string;
  id?: number;
}

export default defineComponent({
  name: "Devices",
  components: {
    DevicesTable,
    CreateDeviceDialog,
    DeleteDeviceDialog
  },
  setup(): devicesSetup {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });
    const brands: ComputedRef<IBrand[]> = computed(
      () => store.getters.brandsList
    );

    const selectedTab: { id: number | null } = reactive({
      id: brands.value?.[0]?.id || null
    });

    const options = {
      viewConfiguration,
      selectedTab
    };

    const {
      requestDevicesHandler,
      requestBrandsHandler,
      addDevice,
      editDevice,
      removeDevice,
      brandsToSelect
    } = useSuportedDevices(options);

    requestBrandsHandler(true);

    watch(selectedTab, requestDevicesHandler, {
      immediate: true,
      deep: true
    });

    watch(viewConfiguration, requestDevicesHandler, {
      immediate: true
    });

    let selectMenu = ref(brandsToSelect(brands.value));

    watch(
      brands,
      updatedBrands => {
        if (!selectedTab.id) {
          selectedTab.id = updatedBrands[0]?.id;
        }
        selectMenu.value = brandsToSelect(updatedBrands);
      },
      {
        immediate: true,
        deep: true
      }
    );

    function selectBrand(id: number): void {
      selectMenu.value.forEach(
        (brand: brandData) => (brand.selected = brand.id === id)
      );
    }

    return {
      ...useUserTable(),
      viewConfiguration,
      selectMenu,
      selectedTab,
      selectBrand,
      addDevice,
      editDevice,
      removeDevice,
      devicesList: computed(() => store.getters.devicesList),
      brandsList: computed(() => store.getters.brandsList),
      totalDevicesCount: computed(() => store.getters.totalDevicesCount),
      requestDevicesHandler,
      requestBrandsHandler,
      changeViewConfigurationHandler({
        page,
        perPage
      }: ITableViewConfiguration): void {
        viewConfiguration.page = page;
        viewConfiguration.perPage = perPage;
      },
      changeTabHandler(tab: IBrand): void {
        if (tab?.id) {
          selectedTab.id = tab.id;
          viewConfiguration.page = 1;
        }
      }
    };
  },

  data() {
    return {
      addDeviceModal: false,
      deleteDeviceDialogOpened: false,
      loadingCreate: false,
      editQuestionModalOpened: false,
      editData: {} as editDeviceData | undefined,
      deleteUserLoading: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" }
      }
    };
  },

  computed: {
    editModalTitle(): string {
      return this.editData?.title || "New Device";
    }
  },

  methods: {
    openForm(): void {
      this.addDeviceModal = true;
    },

    async createDevice(data: editDeviceData) {
      this.loadingCreate = true;
      let success = false;

      if (data.id) {
        success = await this.editDevice(data);
        this.requestDevicesHandler?.();
      } else {
        success = await this.addDevice(data);
        this.requestDevicesHandler?.();
      }

      this.loadingCreate = false;

      if (success) {
        this.loadingCreate = false;
        this.addDeviceModal = false;
      }
    },

    editDeviceModal(id: number) {
      this.editData = this.devicesList.find(device => device.id === id);
      this.addDeviceModal = true;
    },

    async onDeleteConfirm(id: number) {
      this.deleteUserLoading = true;
      const success = await this.removeDevice(id);
      this.deleteUserLoading = false;
      if (success) {
        await this.requestDevicesHandler?.();
        this.deleteDeviceDialogOpened = false;
      }
    },

    deleteDeviceData(id: number) {
      this.editData = this.devicesList.find(device => device.id === id);
      this.deleteDeviceDialogOpened = true;
    },
    hideModal() {
      this.addDeviceModal = false;
      this.editData = {} as editDeviceData;
    }
  }
});
