import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "container locations-page" }
const _hoisted_2 = { class: "account-wrapper pb-5" }
const _hoisted_3 = { class: "table-tabs-wrapper" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "w-100" }
const _hoisted_6 = { class: "table-tabs-heading d-flex align-items-center justify-content-between" }
const _hoisted_7 = { class: "selected-tab-title" }
const _hoisted_8 = {
  key: 0,
  class: "app-toolbar-table__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_HeadToolBar = _resolveComponent("HeadToolBar")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppPreloader = _resolveComponent("AppPreloader")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showHeadToolbar)
      ? (_openBlock(), _createBlock(_component_PageMainHeading, {
          key: 0,
          title: _ctx.tableTitle
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_HeadToolBar, {
          tabs: _ctx.tabsList,
          "initial-selected-tab": _ctx.selectedTabIdx,
          "parent-selected-tab-id": _ctx.selectedTab?.id,
          onChangeTab: _ctx.changeTab
        }, null, 8, ["tabs", "initial-selected-tab", "parent-selected-tab-id", "onChangeTab"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.selectedTab?.title), 1),
                  (!_ctx.hideButton)
                    ? (_openBlock(), _createBlock(_component_AppButton, {
                        key: 0,
                        type: "secondary",
                        size: "lg",
                        onClick: _ctx.addItem
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BtnContent, {
                            icon: "plus",
                            label: "Add new"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Add " + _toDisplayString(_ctx.tableTitle), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (_ctx.appLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_AppPreloader)
                        ]))
                      : (_openBlock(), _createBlock(_component_AppTable, {
                          key: 1,
                          "pagination-capture-key": _ctx.tableTitle,
                          "table-head": _ctx.tableHeadTable,
                          "table-body": _ctx.tableBody,
                          "total-rows": _ctx.totalRows,
                          "current-page": _ctx.currentPage,
                          "per-page": _ctx.perPage,
                          "hide-button": _ctx.hideButton,
                          "space-between": _ctx.spaceBetween,
                          "with-footer": _ctx.withFooter,
                          onChangeViewConfiguration: _ctx.changeViewConfiguration,
                          onSort: _ctx.changeSorting,
                          onEditItem: _ctx.editItem,
                          onDeleteItem: _ctx.removeItem
                        }, null, 8, ["pagination-capture-key", "table-head", "table-body", "total-rows", "current-page", "per-page", "hide-button", "space-between", "with-footer", "onChangeViewConfiguration", "onSort", "onEditItem", "onDeleteItem"]))
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}