import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "account-wrapper container",
  style: {"max-width":"100%","overflow":"auto","margin-top":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTable, {
      "pagination-capture-key": "Logs",
      "table-title": _ctx.tableTitle,
      "current-page": _ctx.parentViewConfiguration.page,
      "per-page": _ctx.parentViewConfiguration.perPage,
      "table-body": _ctx.rows,
      "table-head": _ctx.tableHeadTable,
      "total-rows": _ctx.totalRowsCount,
      "hide-button": _ctx.hideButton,
      filters: _ctx.filters,
      "on-filter-select": _ctx.onFilterSelect,
      onOpenForm: _ctx.openForm,
      onSelectData: _ctx.selectData,
      onEditItem: _ctx.editDeviceAction,
      onRemoveItem: _ctx.deleteDeviceAction,
      onChangeViewConfiguration: _ctx.changeViewConfiguration,
      onSelectSortDate: _ctx.selectSortDate
    }, null, 8, ["table-title", "current-page", "per-page", "table-body", "table-head", "total-rows", "hide-button", "filters", "on-filter-select", "onOpenForm", "onSelectData", "onEditItem", "onRemoveItem", "onChangeViewConfiguration", "onSelectSortDate"])
  ]))
}