import { useStore } from "vuex";
import { Ref, computed, ref, watch } from "vue";
import { switchedLocation } from "@/components/Locations/interfaces";
import { selectedlocations } from "../locations/new/modules/location.update";
import { IBaseLocation } from "@/api/services/packages/esim/location-packages/types";
import { IBaseQuery } from "@/api/interfaces/IApi";

interface editDeviceData {
  title: string;
  active: boolean;
  default: boolean;
  type: string;
  publicKey?: string;
  secretKey?: string;
  countries?: [];
  id?: number;
}

const initialSavedLocations: selectedlocations = {
  countries: [],
  subregions: []
};

export function useStripeAccounts(viewConfiguration: IBaseQuery) {
  const store = useStore();

  const stripeAccounts = computed(() => store.getters.stripeAccounts);
  const stripeAccountsTotal = computed(() => store.getters.stripeAccountsTotal);

  const selectedList: Ref<selectedlocations> = ref({
    ...initialSavedLocations
  });
  const localSelectedList: Ref<selectedlocations> = ref({
    ...initialSavedLocations
  });
  const changeList: Ref<switchedLocation[]> = ref([]);

  const countries = computed(() => store.getters.notExtendedCountries);
  store.dispatch("fetchNotExtendedLocations");

  async function requestStripeAccountsHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch(
      "fetchStripeAccountsList",
      {
        query: viewConfiguration
      }
    );

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  async function addStripeAccount(body: editDeviceData): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    let data = { ...body };
    if (changeList.value.length) {
      data = Object.assign(data, { countries: changeList.value });
    }
    if (!stripeAccounts.value.length) {
      data.default = true;
    }

    const { success, message } = await store.dispatch("addStripeAccount", {
      data
    });

    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function editStripeAccount(body: editDeviceData): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { publicKey, secretKey } = body;
    let data = { ...body };
    if (changeList.value.length) {
      data = Object.assign(data, { countries: changeList.value });
    }
    if (!publicKey) delete data.publicKey;
    if (!secretKey) delete data.secretKey;
    if (stripeAccounts.value.length === 1) {
      data.default = true;
    }

    const { success, message } = await store.dispatch("editStripeAccount", {
      data,
      id: data.id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function removeStripeAccount(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeStripeAccount", {
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function onDeleteStripeAccountConfirm(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeStripeAccount", {
      id
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function restartServerHandler() {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("restartPaymentServer");
    if (success) {
      const sucessMessage =
        "Server rebooted successfully! This may take a few minutes. Refresh the page later to check your Stripe account statuses";
      await store.dispatch("showSuccessNotification", sucessMessage);
    }
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  function toggleSelectedLocation(location: switchedLocation) {
    const { action, id, type = "countries" } = location;
    const inChangesList = changeList.value.find((item: any) => item.id === id);
    const data: any = selectedList.value[type];
    const enabledByServer = data?.find((item: any) => item.id === id);

    if (action === "disable") {
      if (enabledByServer) {
        if (inChangesList) {
          inChangesList.action = "disable";
        } else {
          changeList.value.push(location);
        }
      } else {
        changeList.value = changeList.value.filter(item => item.id !== id);
      }
    } else if (action === "enable") {
      if (enabledByServer) {
        changeList.value = changeList.value.filter(item => item.id !== id);
      } else if (inChangesList) {
        inChangesList.action = "enable";
      } else {
        changeList.value.push(location);
      }
    }
  }

  watch(() => changeList.value, changeListChangeHandler, { deep: true });
  watch(() => selectedList.value, selecetdListChangeHandler, { deep: true });

  function selecetdListChangeHandler({
    countries,
    subregions
  }: selectedlocations) {
    localSelectedList.value = {
      countries: [],
      subregions: []
    };
    const newCountry: any = countries;
    const newSubregion: any = subregions;

    if (newCountry?.length) {
      localSelectedList.value.countries = [...newCountry];
    }
    if (newSubregion.selected?.length) {
      localSelectedList.value.subregions = [...newSubregion.selected];
    }
    if (!newSubregion.selected?.length && !newCountry?.length) {
      localSelectedList.value.subregions = [];
      localSelectedList.value.countries = [];
    }
  }

  function changeListChangeHandler(
    locations: switchedLocation[],
    prev?: switchedLocation[]
  ) {
    const removedFromChangeList = prev
      ? prev.filter(({ id }) => !locations.some(l => l.id === id))
      : [];

    locations.forEach(({ id, action, type = "countries" }) => {
      if (action === "enable") {
        const isExist = localSelectedList.value[type].find(l => l.id === id);

        if (!isExist) {
          const list = countries;
          const fullLocation: IBaseLocation | null =
            list.value.find((item: any) => item.id === id) || null;

          if (fullLocation) {
            localSelectedList.value[type].push(fullLocation);
          }
        }
      } else if (action === "disable") {
        localSelectedList.value[type] = localSelectedList.value[type].filter(
          l => l.id !== id
        );
      }
    });

    removedFromChangeList.forEach(({ id, action, type = "countries" }) => {
      const data: any = selectedList.value[type];
      const isEnabledByServer = data?.find((l: any) => l.id === id);

      if (action === "enable") {
        if (!isEnabledByServer) {
          localSelectedList.value[type] = localSelectedList.value[type].filter(
            l => l.id !== id
          );
        }
      } else {
        const isExist = localSelectedList.value[type].find(l => l.id === id);

        if (isEnabledByServer && !isExist) {
          const list = countries;
          const fullLocation: IBaseLocation | null =
            list.value.find((item: any) => item.id === id) || null;

          if (fullLocation) {
            localSelectedList.value[type].push(fullLocation);
          }
        }
      }
    });
  }

  return {
    countries,
    localSelectedList,
    selectedList,
    stripeAccounts,
    changeList,
    stripeAccountsTotal,
    requestStripeAccountsHandler,
    addStripeAccount,
    editStripeAccount,
    removeStripeAccount,
    onDeleteStripeAccountConfirm,
    toggleSelectedLocation,
    restartServerHandler
  };
}
