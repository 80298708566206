
import { defineComponent, PropType } from "vue";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import { IBaseLocation } from "@/api/services/packages/esim/location-packages/types";
import CountrySwitch from "@/components/ui/Country/CountrySwitch.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import {
  switchedLocation,
  switchLocationDto
} from "@/components/Locations/interfaces";
import { TPackageLocationSingle } from "@/hooks/esim/location-packages/types/locationPackage.types";

export default defineComponent({
  name: "SelectLocationsDialog",
  components: { AppButton, CountrySwitch, Dialog },
  props: {
    opened: { type: Boolean, default: false },
    listType: {
      type: String as PropType<"countries" | "subregions">,
      required: true
    },

    locationType: {
      type: String as PropType<TPackageLocationSingle>,
      required: true
    },

    selectedLocations: {
      type: Array as PropType<IBaseLocation[]>,
      required: true
    },

    baseList: {
      type: Array as PropType<IBaseLocation[]>,
      required: true
    }
  },

  emits: { hide: null, back: null, save: null },

  data(): {
    changeList: switchedLocation[];
  } {
    return { changeList: [] };
  },

  computed: {
    title() {
      const { listType, locationType } = this;
      return `Select ${listType} to add it to a ${locationType}`;
    },

    locations() {
      type TLocation = {
        id: number;
        name: string;
        icon?: string;
        checked: boolean;
      };

      type locationsObject = {
        [key: string]: TLocation[];
      };

      const initialData: locationsObject = {};

      return Object.entries(
        this.baseList
          .map(l => l)
          .reduce((acc: locationsObject, location) => {
            const letter: string = location.name[0];

            const inChangeListAction = this.changeList.find(
              ({ id }) => id === location.id
            )?.action;

            const inChangeListStatus = inChangeListAction === "enable";

            const __location: TLocation = {
              id: location.id,
              name: location.name,
              icon: location.icon,
              checked: inChangeListAction
                ? inChangeListStatus
                : !!this.selectedLocations?.find(({ id }) => id === location.id)
            };

            if (!acc[letter]) {
              acc[letter] = [__location];
              return acc;
            }

            acc[letter] = [...acc[letter], __location];
            return acc;
          }, initialData)
      ).map(([letter, locations]) => {
        return {
          letter,
          locationsList: locations
        };
      });
    }
  },

  methods: {
    hide() {
      this.$emit("hide");
      this.changeList = [];
    },

    back() {
      this.$emit("back");
    },

    saveHandler() {
      this.$emit("save", this.changeList);
      this.changeList = [];
      this.$emit("back");
    },

    switchLocation({ id, checked }: switchLocationDto) {
      const alreadyChangedLocation = this.changeList.find(location => {
        return location.id === id;
      });

      const action = checked ? "enable" : "disable";

      if (alreadyChangedLocation) {
        alreadyChangedLocation.action = action;
        return;
      }

      this.changeList.push({ action, id, type: this.listType });
    }
  }
});
