import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbarTable = _resolveComponent("AppToolbarTable")!

  return (_openBlock(), _createBlock(_component_AppToolbarTable, {
    "pagination-capture-key": "Devices",
    "table-title": _ctx.tableTitle,
    "table-head-tabs": _ctx.brandsData,
    "table-body": _ctx.rows,
    "total-rows": _ctx.totalRowsCount,
    "current-page": _ctx.viewConfiguration.page,
    "per-page": _ctx.viewConfiguration.perPage,
    "table-head-table": _ctx.tableHeadTable,
    onChangeViewConfiguration: _ctx.changeViewConfiguration,
    onChangeTab: _ctx.changeTab,
    onSort: _ctx.changeSorting,
    onOpenForm: _ctx.openForm,
    onEditItem: _ctx.editDeviceAction,
    onRemoveItem: _ctx.deleteDeviceAction
  }, null, 8, ["table-title", "table-head-tabs", "table-body", "total-rows", "current-page", "per-page", "table-head-table", "onChangeViewConfiguration", "onChangeTab", "onSort", "onOpenForm", "onEditItem", "onRemoveItem"]))
}