import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "m-3 mt-0 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_StripeAccountsTable = _resolveComponent("StripeAccountsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!
  const _component_EditStripeAccountDialog = _resolveComponent("EditStripeAccountDialog")!
  const _component_RestartServerDialog = _resolveComponent("RestartServerDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Stripe Accounts" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppButton, {
                type: "danger",
                size: "lg",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restartServerDialogOpened = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, {
                    icon: "reload",
                    label: "Restart Server"
                  })
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_AppButton, {
              type: "secondary",
              size: "lg",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editStripeAccountDialogOpened = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BtnContent, {
                  icon: "plus",
                  label: "Add Stripe Account"
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": _ctx.stripeAccounts.length,
      "empty-button-text": "Add Stripe Account",
      "empty-key": "stripe accounts",
      onEmptyButtonClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createStripeAccountDialogOpened = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_StripeAccountsTable, {
          "stripe-accounts-list": _ctx.stripeAccounts,
          "total-rows-count": _ctx.stripeAccountsTotal,
          "initial-view-configuration": _ctx.viewConfiguration,
          sort: _ctx.sortConfig,
          onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
          onRemovingStripeAccount: _ctx.removingStripeAccount,
          onEditingStripeAccount: _ctx.editingStripeAccount
        }, null, 8, ["stripe-accounts-list", "total-rows-count", "initial-view-configuration", "sort", "onChangeViewConfiguration", "onRemovingStripeAccount", "onEditingStripeAccount"])
      ]),
      _: 1
    }, 8, ["show-table"]),
    _createVNode(_component_DeleteBrandDialog, {
      title: _ctx.editText,
      opened: _ctx.deleteStripeAccountDialogOpened,
      loading: _ctx.isLoading,
      "edit-data": _ctx.editData,
      "is-show": false,
      hide: false,
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteStripeAccountDialogOpened = false)),
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.onDeleteCancel
    }, null, 8, ["title", "opened", "loading", "edit-data", "onConfirm", "onCancel"]),
    _createVNode(_component_EditStripeAccountDialog, {
      title: _ctx.editText,
      loading: _ctx.isLoading,
      opened: _ctx.editStripeAccountDialogOpened,
      "edit-data": _ctx.editData.id ? _ctx.editData : null,
      "base-locations": { countries: _ctx.countries },
      "selected-locations-lists": _ctx.localSelectedList,
      onToggleSelectedLocations: _ctx.toggleSelectedLocation,
      onHide: _ctx.hideEditDialogHandler,
      onSubmit: _ctx.submit
    }, null, 8, ["title", "loading", "opened", "edit-data", "base-locations", "selected-locations-lists", "onToggleSelectedLocations", "onHide", "onSubmit"]),
    _createVNode(_component_RestartServerDialog, {
      opened: _ctx.restartServerDialogOpened,
      loading: _ctx.isLoading,
      onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.restartServerDialogOpened = false)),
      onConfirm: _ctx.onRestartConfirm,
      onCancel: _ctx.onRestartCancel
    }, null, 8, ["opened", "loading", "onConfirm", "onCancel"])
  ], 64))
}