
import { defineComponent, watch, reactive } from "vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import StripeAccountsTable from "@/components/Table/ts/instances/stripe-accounts/StripeAccountsTable.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";

import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";
import EditStripeAccountDialog from "@/components/ui/Modal/Dialog/instances/EditStripeAccountDialog.vue";
import RestartServerDialog from "@/components/ui/Modal/Dialog/instances/RestartServerDialog.vue";

import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useStripeAccounts } from "@/hooks/stripe-accounts/useStripeAccounts";
import { IStripeAccount } from "@/store/interfaces/stripe-accounts/IStripeAccounts";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { sortOrders } from "@/enums/main/sortOrders";

const initialEditData = () => {
  return {
    title: "",
    type: "",
    active: false,
    default: false,
    countries: [],
    id: null
  };
};
export default defineComponent({
  name: "StripeAccounts",
  components: {
    DeleteBrandDialog,
    EditStripeAccountDialog,
    BtnContent,
    AppButton,
    PageMainHeading,
    StripeAccountsTable,
    AppTableController,
    RestartServerDialog
  },

  setup() {
    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    watch(viewConfiguration, () => requestStripeAccountsHandler());

    const {
      countries,
      localSelectedList,
      selectedList,
      stripeAccounts,
      changeList,
      stripeAccountsTotal,
      requestStripeAccountsHandler,
      onDeleteStripeAccountConfirm,
      editStripeAccount,
      addStripeAccount,
      toggleSelectedLocation,
      restartServerHandler
    } = useStripeAccounts(viewConfiguration);
    requestStripeAccountsHandler();

    function changeViewConfigurationHandler({
      page,
      perPage
    }: ITableViewConfiguration): void {
      if (
        page === viewConfiguration.page &&
        perPage !== viewConfiguration.perPage
      ) {
        viewConfiguration.page = 1;
      } else {
        viewConfiguration.page = page;
      }

      viewConfiguration.perPage = perPage;
    }

    return {
      countries,
      viewConfiguration,
      localSelectedList,
      selectedList,
      stripeAccounts,
      changeList,
      stripeAccountsTotal,
      requestStripeAccountsHandler,
      onDeleteStripeAccountConfirm,
      editStripeAccount,
      addStripeAccount,
      changeViewConfigurationHandler,
      toggleSelectedLocation,
      restartServerHandler
    };
  },

  data() {
    return {
      title: "New Stripe Account",
      editText: "",
      editId: 0,
      createStripeAccountDialogOpened: false,
      editStripeAccountDialogOpened: false,
      deleteStripeAccountDialogOpened: false,
      restartServerDialogOpened: false,
      isLoading: false,
      sortConfig: {
        byTitle: { order: sortOrders.asc, keyName: "title" }
      },
      editData: {
        title: "",
        type: "",
        active: false,
        default: false,
        countries: [],
        id: null
      }
    };
  },

  computed: {
    emptyText(): string {
      return `No stripe account found!`;
    }
  },

  methods: {
    async removingStripeAccount(cId: number) {
      this.editId = cId;
      this.deleteStripeAccountDialogOpened = true;
    },
    async submit(data: IStripeAccount) {
      this.isLoading = true;
      let success;
      if (!data.id) {
        success = await this.addStripeAccount(data);
      } else {
        success = await this.editStripeAccount(data);
      }
      if (success) {
        this.hideEditDialogHandler();
        await this.requestStripeAccountsHandler();
      }
      this.isLoading = false;
    },
    async onDeleteConfirm() {
      this.isLoading = true;
      const success = await this.onDeleteStripeAccountConfirm(this.editId);
      if (success) {
        this.deleteStripeAccountDialogOpened = false;
        await this.requestStripeAccountsHandler();
      }
      this.isLoading = false;
    },

    async editingStripeAccount(cId: number) {
      this.editId = cId;
      this.editData =
        this.stripeAccounts.find((item: IStripeAccount) => item.id == cId) ||
        undefined;

      if (this.editData && this.editData?.countries) {
        this.selectedList = {
          countries: this.editData.countries,
          subregions: []
        };
      }
      this.editData && (this.editStripeAccountDialogOpened = true);
    },

    hideEditDialogHandler() {
      this.editStripeAccountDialogOpened = false;
      this.selectedList = {
        countries: [],
        subregions: []
      };
      this.changeList = [];
      this.editData = initialEditData();
    },

    onDeleteCancel() {
      this.deleteStripeAccountDialogOpened = false;
    },

    async onRestartConfirm() {
      try {
        this.isLoading = true;
        const success = await this.restartServerHandler();
        if (success) {
          await this.requestStripeAccountsHandler();
        }
      } finally {
        this.onRestartCancel();
        this.isLoading = false;
      }
    },

    onRestartCancel() {
      this.restartServerDialogOpened = false;
    }
  }
});
