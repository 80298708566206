import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_2 = { class: "page-heading-toolbar--col" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogsDialog = _resolveComponent("LogsDialog")!
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_AppCalendar = _resolveComponent("AppCalendar")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_LogsTable = _resolveComponent("LogsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LogsDialog, {
      style: {"width":"80%"},
      content: _ctx.content,
      opened: _ctx.contentModalOpened,
      onHide: _ctx.clearData
    }, null, 8, ["content", "opened", "onHide"]),
    _createVNode(_component_PageMainHeading, {
      title: "Logs",
      class: "container"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ShadowControl, {
              id: "admins-search",
              class: "search-control",
              icon: "search",
              placeholder: "Search...",
              name: "admins-search",
              "input-timeout": true,
              "initial-data": _ctx.queryI,
              onChange: _ctx.searchHandler
            }, null, 8, ["initial-data", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppCalendar, {
              id: "calendar-period",
              "calendar-text": "Choose period",
              "button-text": "Filter",
              multiple: true,
              onSelectDate: _ctx.selectSortDateHandler
            }, null, 8, ["onSelectDate"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AppButton, {
              type: "secondary",
              size: "lg",
              onClick: _ctx.removeLogs
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Remove All Logs ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.logsList?.length,
      "empty-key": "logs",
      onEmptyButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editServerDialogOpened = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LogsTable, {
          sort: _ctx.sortConfig,
          "logs-list": _ctx.logsList,
          "total-rows-count": _ctx.logsTotal,
          "hide-button": _ctx.hideButton,
          "table-title": 'Logs',
          "parent-view-configuration": _ctx.viewConfiguration,
          "on-filter-select": _ctx.onFilterSelect,
          filters: _ctx.filter,
          onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
          onSelectData: _ctx.selectDataHandler
        }, null, 8, ["sort", "logs-list", "total-rows-count", "hide-button", "parent-view-configuration", "on-filter-select", "filters", "onChangeViewConfiguration", "onSelectData"])
      ]),
      _: 1
    }, 8, ["show-table"]),
    _createVNode(_component_DeleteDialog, {
      opened: _ctx.removeLogsModal,
      loading: _ctx.isLoading,
      onConfirm: _ctx.onDeleteConfirmHandler,
      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeLogsModal = false))
    }, null, 8, ["opened", "loading", "onConfirm"])
  ], 64))
}