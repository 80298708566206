import { reactive, ref } from "vue";
import { userId } from "@/store/interfaces/auth/common";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import {
  IDeleteUser,
  ISelectedUser
} from "@/hooks/tables/modules/usersTable/types";
import { useStore } from "vuex";

export function useDeleteUser(): IDeleteUser {
  const store = useStore();

  const deleteUserDialogOpened = ref(false);
  const deleteUserLoading = ref(false);

  const selectedUser: ISelectedUser = reactive({
    id: null,
    name: null,
    email: null,
    avatar: null
  });

  return {
    deleteUserDialogOpened,
    selectedUser,
    deleteUserLoading,
    selectUserToDelete(userList: IUserAccount[], id: userId) {
      const user = userList.find(user => id === user.id);

      if (user) {
        const { email, fullName: name, image: avatar } = user;

        selectedUser.email = email;
        selectedUser.name = name;
        selectedUser.avatar = avatar;
        selectedUser.id = id;

        deleteUserDialogOpened.value = true;
      }
    },

    async deleteUser(onUserDelete?: Function) {
      deleteUserLoading.value = true;
      const { success, message } = await store.dispatch("removeUser", {
        id: selectedUser.id
      });

      if (!success) {
        await store.dispatch("showErrorNotification", message);
      }

      onUserDelete?.();
      deleteUserDialogOpened.value = false;
      deleteUserLoading.value = false;
    }
  };
}
