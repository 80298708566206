export const useCalendar = {
  emits: ["select"],
  data() {
    return {
      date: "",
      opened: false,
      calendarNode: null,
      parentEl: null
    };
  },
  watch: {
    date(date) {
      this.$emit("select", date);
    }
  },
  methods: {
    show() {
      this.calendarNode.click();
    },

    calendarInitHandler(calendar) {
      this.calendarNode = calendar;
    }
  },
  mounted() {
    this.parentEl = this.$refs.parentDropdown;
  }
};
