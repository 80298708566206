import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "avatar-upload" }
const _hoisted_2 = { class: "avatar-upload-uploader" }
const _hoisted_3 = { class: "avatar-upload-text" }
const _hoisted_4 = { class: "light-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUploader = _resolveComponent("FileUploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex", {
        'flex-column': _ctx.direction === 'column',
        'align-items-center': _ctx.direction === 'column',
        'text-center': _ctx.direction === 'column'
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FileUploader, _mergeProps({
          id: "avatar",
          placeholder: _ctx.placeholder,
          accept: ".jpg, .jpeg, .png"
        }, _ctx.$attrs, {
          onFileChanged: _cache[0] || (_cache[0] = file => _ctx.$emit('fileChanged', file))
        }), null, 16, ["placeholder"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, "Size: " + _toDisplayString(_ctx.size) + " pixels", 1),
        _createElementVNode("p", null, "Format: " + _toDisplayString(_ctx.format), 1)
      ])
    ], 2)
  ]))
}