import { userType } from "@/api/interfaces/users/users.dto";
import { useCreateUser } from "@/hooks/tables/modules/usersTable/useCreateUser";
import {
  ICreateUser,
  IDeleteUser
} from "@/hooks/tables/modules/usersTable/types";
import { useDeleteUser } from "@/hooks/tables/modules/usersTable/useDeleteUser";

export type userExtraInfo = {
  type?: userType;
};

export interface IUserUserTable extends ICreateUser, IDeleteUser {}

export function useUserTable(): IUserUserTable {
  const creationData: ICreateUser = useCreateUser();
  const deleteData: IDeleteUser = useDeleteUser();

  return { ...creationData, ...deleteData };
}
