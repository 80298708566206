
import SvgIcon from "@/components/ui/SvgIcon.vue";
import { fileRender } from "@/utills/files/fileRender";
import { defineComponent } from "vue";
export default defineComponent({
  name: "FileUploader",
  components: { SvgIcon },
  emits: {
    fileChanged: null
  },

  props: {
    id: { type: String, required: true },
    previewUrl: { type: String, default: null },
    placeholder: {
      type: String,
      required: false,
      default: require("@/assets/icons/avatar-placeholder.svg")
    }
  },

  data() {
    return {
      renderedPreview: this.previewUrl,
      file: null
    } as {
      renderedPreview: string;
      file: File | null;
    };
  },

  watch: {
    file(file: File | null) {
      this.$emit("fileChanged", file);
    }
  },

  methods: {
    async uploadHandler({ target }: Event): Promise<void> {
      const evtTarget: HTMLInputElement | null = target as HTMLInputElement;

      if (evtTarget) {
        const files: FileList | null = evtTarget.files;

        if (files) {
          const file: File = files[0];

          if (file) {
            this.file = file;
            this.renderedPreview = await fileRender(file);

            return;
          }
        }
      }

      this.file = null;
    }
  }
});
