
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import SelectedNestedLocations from "@/components/Locations/SelectedNestedLocations.vue";
import { defineComponent } from "@vue/runtime-core";
import { nextTick } from "vue";
import { switchedLocation } from "@/components/Locations/interfaces";
import SelectLocationsDialog from "@/components/Locations/SelectLocationsDialog.vue";
import ChangeDefaultDialog from "@/components/ui/Modal/Dialog/instances/ChangeDefaultStripeDialog.vue";

export default defineComponent({
  name: "EditStripeAccountDialog",
  components: {
    AppButton,
    AppInput,
    Dialog,
    AppInputsValidationProvider,
    AppCheckbox,
    SelectInput,
    SelectedNestedLocations,
    SelectLocationsDialog,
    ChangeDefaultDialog
  },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "Create User" },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    editData: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    selectedLocationsLists: {
      type: Object,
      default: () => ({ countries: [] })
    },
    baseLocations: {
      type: Object,
      default: () => ({ countries: [] })
    }
  },
  emits: ["submit", "toggleSelectedLocations", "hide"],
  data() {
    return {
      buttonTextUpdated: this.buttonText || this.title,
      editingTitle: "",
      name: "",
      isPasswordTouch: false,
      valid: false,
      isChecked: false,
      modalValue: {
        title: "",
        type: "",
        active: false,
        default: false,
        secretKey: "",
        publicKey: "",
        countries: [],
        id: null
      },
      active: false,
      isEditCategory: false,
      isCreateCategory: false,
      stripeAccountsTypes: ["basic", "reserve"].map(t => ({
        name: t,
        id: t,
        selected: false
      })),
      localOpened: false,
      selectListType: "countries",
      locationsSelectOpened: false,
      changeDefaultDialogOpened: false
    };
  },
  computed: {
    currentLocationsLists() {
      return (
        Object.values(this.selectedLocationsLists).find(list => list.length) ??
        []
      );
    },
    baseLoactionsList() {
      return this.baseLocations[this.selectListType];
    }
  },

  watch: {
    editData: {
      handler(data) {
        this.modalValue = { ...data };
        data?.type && this.selectAccountType(data.type);
        this.name = data?.title;
      },
      deep: true
    },
    opened() {
      this.localOpened = this.opened;
      if (!this.opened) {
        this.hide();
      }
    }
  },
  methods: {
    submitHandler() {
      if (this.modalValue.default) {
        this.toggleChangeDefault(true);
      } else {
        this.submit();
      }
    },
    submit() {
      const data = {
        id: this.editData?.id,
        title: this.modalValue.title,
        active: this.modalValue.active,
        default: this.modalValue.default,
        type: this.stripeAccountsTypes.find(t => t.selected)?.name,
        secretKey: this.modalValue.secretKey,
        publicKey: this.modalValue.publicKey
      };
      this.$emit("submit", data);
    },

    selectAccountType(id?: string) {
      if (id) {
        this.stripeAccountsTypes.forEach(t => (t.selected = t.id === id));
      } else {
        this.stripeAccountsTypes.forEach(t => (t.selected = false));
      }
    },

    onChangeErrorState(error: any) {
      this.valid = !error;
    },

    hide() {
      this.modalValue = {
        title: "",
        type: "",
        active: true,
        secretKey: "",
        publicKey: "",
        default: false,
        countries: [],
        id: null
      };
      this.name = "";
      this.selectAccountType();
      this.$emit("hide");
    },

    toggleSelectLocations(type: "countries", open?: boolean) {
      if (open) {
        this.localOpened = !open;
        nextTick(() => {
          this.locationsSelectOpened = this.locationsSelectOpened =
            open ?? false;
        });
      } else {
        this.locationsSelectOpened = this.locationsSelectOpened = open ?? false;

        nextTick(() => {
          this.localOpened = !open;
        });
      }
    },
    toggleSelectedLocation(location: switchedLocation) {
      this.$emit("toggleSelectedLocations", location);
    },
    saveSelectedLocationsList(locations: switchedLocation[]) {
      locations.forEach(location => {
        this.toggleSelectedLocation(location);
      });
    },
    toggleChangeDefault(open?: boolean) {
      if (open) {
        this.localOpened = !open;
        nextTick(() => {
          this.changeDefaultDialogOpened = this.changeDefaultDialogOpened =
            open ?? false;
        });
      } else {
        this.changeDefaultDialogOpened = this.changeDefaultDialogOpened = open ?? false;

        nextTick(() => {
          this.localOpened = !open;
        });
      }
    },
    onChangeDefaultConfirm() {
      this.submit();
      this.toggleChangeDefault(false);
    }
  }
});
