<template>
  <input
    ref="calendar"
    value="Choose period"
    class="app-calendar"
    @change="date = $event.target.value"
  />
</template>

<script>
import { getId } from "@/utills/getId";
import moment from "moment";
import Litepicker from "litepicker";
export default {
  name: "DatePicker",
  props: {
    parentEl: { type: HTMLElement, required: true },
    activeFrom: { type: String, default: "" },
    activeTo: { type: String, default: "" }
  },
  emits: ["open", "close", "select", "init"],
  data() {
    return {
      date: "",
      customButton: null,
      updatedDate: "",
      id: getId("calendar"),
      opened: false,
      receivedDate: {
        monthFrom: "",
        monthTo: "",
        dayFrom: "",
        dayTo: "",
        yearFrom: "",
        yearTo: ""
      }
    };
  },
  watch: {
    updatedDate(date) {
      this.$emit("select", date);
    },

    receivedDate() {
      this.setUpdatedDate();
    },

    activeFrom: {
      handler() {
        if (this.activeFrom && this.activeTo) {
          this.getDateObject(this.activeFrom, this.activeTo);
          this.setUpdatedDate();
        }
      },
      immediate: true
    },

    opened(opened) {
      const event = opened ? "open" : "close";
      this.$emit(event);

      if (opened) {
        this.$refs.calendar.click();
      }
    }
  },
  mounted() {
    if (this.$refs.calendar) {
      this.$emit("init", this.$refs.calendar);

      setTimeout(() => {
        this.picker = new Litepicker({
          element: this.$refs.calendar,
          singleMode: false,
          dropdowns: false,
          lang: "en",
          parentEl: this.parentEl,
          hoveringTooltip: false,
          position: "right bottom",
          autoApply: false,
          allowRepick: true,
          startDate: this.activeFrom || "",
          endDate: this.activeTo || "",
          buttonText: {
            apply: "Done",
            cancel: "Clear",
            previousMonth: `
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.53033 9.53033C5.23744 9.82322 4.76256 9.82322 4.46967 9.53033L0.46967 5.53033C0.176776 5.23744 0.176776 4.76256 0.46967 4.46967L4.46967 0.469669C4.76256 0.176777 5.23744 0.176777 5.53033 0.469669C5.82322 0.762563 5.82322 1.23744 5.53033 1.53033L2.06066 5L5.53033 8.46967C5.82322 8.76256 5.82322 9.23744 5.53033 9.53033Z" fill="#333333"/>
            </svg>
            `,
            nextMonth: `
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5.53033 4.46967C5.82322 4.76256 5.82322 5.23744 5.53033 5.53033L1.53033 9.53033C1.23744 9.82322 0.762563 9.82322 0.46967 9.53033C0.176777 9.23744 0.176777 8.76256 0.46967 8.46967L3.93934 5L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#333333"/>
            </svg>
`
          }
        });

        this.picker.on("show", () => {
          this.opened = true;
        });

        this.picker.on("hide", () => {
          this.opened = false;
        });

        this.picker.on("selected", (start, end) => {
          this.getDateObject(start.dateInstance, end.dateInstance);
        });

        this.picker.on("render", ui => {
          if (ui) {
            const footer = ui.querySelector(".container__footer");

            footer.insertAdjacentHTML(
              "afterbegin",
              `<button class="button button-size-md button-default-transparent">Clear</button>`
            );

            this.customButton = footer.firstElementChild;
            this.customButton.addEventListener("click", this.clear);
          }
        });

        this.picker.on("hide", () => {
          if (this.customButton) {
            this.customButton.removeEventListener("click", this.clear);
          }
        });
      }, 200);
    }
  },

  methods: {
    clear() {
      if (this.picker) {
        this.picker.clearSelection();
      }

      this.getDateObject(null);
    },

    getDateObject(start, end) {
      let dateObj = {};

      if (start) {
        const from = moment(start);
        dateObj = {
          monthFrom: from.format("MMM"),
          yearFrom: from.format("yyyy"),
          dayFrom: from.format("DD")
        };

        if (end) {
          const to = moment(end);
          dateObj = {
            ...dateObj,
            ...{
              monthTo: to.format("MMM"),
              yearTo: to.format("YYYY"),
              dayTo: to.format("DD")
            }
          };
        }

        this.receivedDate = {
          ...this.receivedDate,
          ...dateObj
        };
      } else {
        this.receivedDate = Object.fromEntries(
          Object.entries(this.receivedDate).map(([key]) => [key, ""])
        );
      }
    },

    setUpdatedDate() {
      const {
        dayFrom,
        dayTo,
        monthFrom,
        monthTo,
        yearFrom,
        yearTo
      } = this.receivedDate;
      let dateErr = false;
      let date = `${monthFrom} ${dayFrom}`;
      for (let dateType in this.receivedDate) {
        if (!this.receivedDate[dateType]) dateErr = true;
      }
      if (dayFrom === "Invalid date" || dateErr) {
        date = "Choose period";
      } else {
        if (yearTo && yearTo !== yearFrom && yearFrom) {
          date = `${date}, ${yearFrom}`;
        }

        if (monthTo && monthTo !== monthFrom) {
          date = `${date} - ${monthTo} ${dayTo}`;
        } else if (dayTo && dayTo !== dayFrom) {
          date = `${date} - ${dayTo}`;
        }

        date = `${date}, ${yearTo}`;
      }
      const activeFrom = moment(
        `${monthFrom + " " + dayFrom + " " + yearFrom}`
      ).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      const activeTo = moment(`${monthTo + " " + dayTo + " " + yearTo}`).format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      );
      this.updatedDate = { date, value: { activeFrom, activeTo } };
    }
  }
};
</script>

<style lang="scss">
$inputWidth: 180px;

.app-calendar {
  width: $inputWidth;
  height: $field-height;
  background-color: $color-contrast-light;
  border-radius: $border-radius;
  box-shadow: $shadow-700;

  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

:root {
  --litepicker-day-width: 45px;
  --litepicker-is-in-range-color: #eaf2fd;
  --litepicker-is-start-color-bg: #{$color-primary-blue};
}

.litepicker {
  top: 0 !important;
  right: 0 !important;
  left: auto !important;
  font: inherit !important;
  z-index: $aboveOverlay;
  box-shadow: 0 25px 35px rgba(#000, 0.07) !important;
  border-radius: $border-radius;
  border-top-right-radius: 0 !important;
  padding: 30px 20px !important;
  background-color: #fff;

  .container__months {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .container__footer {
    box-shadow: none !important;
    background: transparent;
    padding-top: 20px;
    margin-bottom: -10px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button-cancel {
      display: none;
    }

    .preview-date-range {
      display: none !important;
    }

    .button-apply {
      @include getButtonStyles("secondary", "md");
      min-width: 114px;
    }
  }

  .month-item-header {
    padding-top: 0 !important;
    padding-bottom: 25px !important;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      strong,
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .container__days {
    margin-top: 10px;
  }
}

.day-item {
  height: var(--litepicker-day-width);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }

  &.is-start-date {
    z-index: 3;

    & + .is-in-range {
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 100%;
        width: 17px;
        height: 100%;
        background-color: var(--litepicker-is-in-range-color);
        pointer-events: none;
      }
    }
  }

  &.is-in-range {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 17px;
      height: 100%;
      background-color: var(--litepicker-is-in-range-color);
      pointer-events: none;
    }
  }

  &:nth-child(7n + 7):not(.is-start-date):not(.is-end-date) {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;

    & + .day-item:not(.is-start-date):not(.is-end-date) {
      border-top-left-radius: $border-radius !important;
      border-bottom-left-radius: $border-radius !important;
    }

    &:after {
      display: none;
    }
  }

  &.is-start-date,
  &.is-end-date {
    position: relative;
    border-radius: 50% !important;
    z-index: 9;
  }
}
</style>
