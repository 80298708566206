
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  ComputedRef,
  defineComponent,
  PropType,
  computed,
  toRefs,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";
import { IStripeAccounts } from "@/api/interfaces/stripe-accounts/IStripeAccounts";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

type tableHeadCells = ITableHeadCell[];
export type stripeAccountsTableSort = {
  byTitle: sortItem;
};

export default defineComponent({
  name: "StripeAccountsTable",
  components: { AppTable },
  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    stripeAccountsList: {
      type: Array as PropType<IStripeAccounts[]>,
      required: true
    },

    sort: {
      type: Object as PropType<stripeAccountsTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },
  emits: {
    toggleBlock: null,
    delete: null,
    editingStripeAccount: null,
    removingStripeAccount: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    const { byTitle } = toRefs(props.sort);

    const initialSortOptions: stripeAccountsTableSort = {
      byTitle: { order: sortOrders.turnOff, keyName: "title" }
    };
    // table model
    const stripeAccountsList: ComputedRef<IStripeAccounts[]> = computed(
      () => props.stripeAccountsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },

      initialSortOptions,
      model: stripeAccountsList,
      generateRows(stripeAccountsList: IStripeAccounts[]): ITableBodyRow[] {
        return stripeAccountsList.map(
          (account: IStripeAccounts): ITableBodyRow => {
            const {
              title,
              type,
              active,
              default: stripeDefault,
              id,
              isUsed
            } = account;
            return {
              cells: [
                {
                  label: title
                },
                {
                  badge: {
                    label: isUsed ? "Used" : "Not used",
                    type: isUsed ? badgeTypes.lightSecondary : badgeTypes.danger
                  }
                },
                {
                  label: type
                },
                {
                  badge: {
                    label: active ? "Active" : "Inactive",
                    type: active ? badgeTypes.lightSecondary : badgeTypes.danger
                  }
                },
                {
                  badge: {
                    label: stripeDefault ? "True" : "False",
                    type: stripeDefault
                      ? badgeTypes.lightSecondary
                      : badgeTypes.base
                  }
                },
                {
                  userActions: true
                }
              ],
              id: id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });
    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  computed: {
    tableHead(): tableHeadCells {
      return [
        {
          size: "fluid",
          label: "title",
          id: "account-title"
        },
        {
          size: "md",
          label: "Usage status",
          id: "account-use"
        },
        {
          size: "sm",
          label: "Type",
          id: "account-type"
        },
        {
          size: "sm",
          label: "Status",
          id: "account-status"
        },
        {
          size: "sm",
          label: "Default",
          id: "account-default-status"
        },
        {
          size: "xs",
          label: "Action",
          id: "account-action"
        }
      ];
    }
  },

  methods: {
    DeletingInfo(accId: any, info: IStripeAccounts) {
      this.$emit("removingStripeAccount", accId, info);
    },

    EditingInfo(accId: IStripeAccounts, info: IStripeAccounts) {
      this.$emit("editingStripeAccount", accId, info);
    }
  }
});
