<template>
  <div class="country-switch">
    <SettingsItem :id="`country-select-${id}`" v-model="localChecked">
      <template #title>
        <Country :id="id" :image-path="icon" :name="name" />
      </template>
    </SettingsItem>
  </div>
</template>

<script>
import SettingsItem from "../../Settings/SettingsItem.vue";
import Country from "./Country.vue";
export default {
  name: "CountrySwitch",
  components: { Country, SettingsItem },
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true },
    icon: { type: String, default: "" },
    checked: { type: Boolean, required: true }
  },

  emits: ["switch"],

  data() {
    return {
      localChecked: this.checked
    };
  },

  watch: {
    localChecked() {
      this.$emit("switch", { id: this.id, checked: this.localChecked });
    },

    checked(checked) {
      this.localChecked = checked;
    }
  }
};
</script>

<style lang="scss">
.country-switch {
  .settings-item {
    padding: 10px 0;

    font-weight: 600;
  }
}
</style>
