
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import AppInput from "@/components/Form/AppInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
interface AddData {
  title: string;
  brand: IBrand;
  icon: string | File;
  active: boolean;
}

interface EditData {
  id: number;
  title: string;
  brand: brandData;
  icon: string | File;
  active: boolean;
}

interface brandData {
  id: string;
  selected: boolean;
  title: string;
  name: string;
}

export default defineComponent({
  name: "CreateDeviceDialog",
  components: {
    AppButton,
    AppInput,
    AvatarUpload,
    Dialog,
    SelectInput,
    AppCheckbox
  },
  emits: {
    submit: null,
    hide: null,
    selectMenuItem: null
  },
  props: {
    // disabled: { type: Boolean, default: true },
    data: {
      type: Object as PropType<EditData>,
      required: false
    },
    opened: { type: Boolean, default: false },
    currentBrand: { type: Object as PropType<IBrand>, required: true },
    title: { type: String, default: "" },
    brands: { type: Array as PropType<brandData[]>, required: true },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  inheritAttrs: false,
  data() {
    return {
      editData: {} as EditData,
      avatar: "" as string | File,
      selectedBrand: 0 as number,
      valid: false as boolean
    };
  },
  computed: {
    editModalTitle() {
      return this.title;
    }
  },
  watch: {
    data(data) {
      if (data) {
        this.editData = { ...data };
        this.selectBrandHandler(this.currentBrand.id);
      }
    },
    editData: {
      handler(data: AddData) {
        if (
          data?.title?.length > 4 &&
          this?.brands.some(el => el.selected) &&
          data?.icon
        ) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      },
      deep: true
    }
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    hide() {
      this.editData = {} as EditData;
      this.$emit("hide");
    },
    selectBrandHandler(id: number) {
      this.$emit("selectMenuItem", id);
    },
    fileUpload(file: File) {
      this.editData.icon = file;
    },
    addDevice() {
      if (this.valid) {
        this.editData.title =encodeURIComponent(this.editData.title);
        const data = this.editData;
        this.editData = {} as EditData;
        this.$emit("submit", {
          ...data,
          brand: this.brands.find(brand => brand.selected)?.id
        });
      }
    }
  }
});
