import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "select-country-dialog" }
const _hoisted_2 = { class: "select-country-dialog--body" }
const _hoisted_3 = { class: "country-categories" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "countries-list" }
const _hoisted_6 = { class: "select-country-dialog--footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountrySwitch = _resolveComponent("CountrySwitch")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    opened: _ctx.opened,
    inserted: true,
    title: _ctx.title,
    class: "select-country-dialog--modal",
    onHide: _ctx.hide,
    onBack: _ctx.back
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, ({ letter, locationsList }) => {
              return (_openBlock(), _createElementBlock("li", {
                key: letter,
                class: "country-categories-item mt-4"
              }, [
                _createElementVNode("h2", _hoisted_4, _toDisplayString(letter), 1),
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationsList, ({ id, name, checked, icon }) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: id,
                      class: "countries-list-item"
                    }, [
                      _createVNode(_component_CountrySwitch, {
                        id: id,
                        name: name,
                        checked: checked,
                        icon: icon,
                        onSwitch: _ctx.switchLocation
                      }, null, 8, ["id", "name", "checked", "icon", "onSwitch"])
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AppButton, {
            type: "secondary",
            size: "xl",
            onClick: _ctx.saveHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["opened", "title", "onHide", "onBack"]))
}