import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-tabs-wrapper--head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTabsHead = _resolveComponent("AppTabsHead")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTabsHead, {
      modelValue: _ctx.selectedTabIdx,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabIdx) = $event)),
      tabs: _ctx.tabs
    }, null, 8, ["modelValue", "tabs"])
  ]))
}