import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateDeviceDialog = _resolveComponent("CreateDeviceDialog")!
  const _component_DevicesTable = _resolveComponent("DevicesTable")!
  const _component_DeleteDeviceDialog = _resolveComponent("DeleteDeviceDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateDeviceDialog, {
      data: _ctx.editData?.id ? _ctx.editData : null,
      opened: _ctx.addDeviceModal,
      title: _ctx.editModalTitle,
      loading: _ctx.loadingCreate,
      brands: _ctx.selectMenu,
      "current-brand": _ctx.selectedTab,
      onSubmit: _ctx.createDevice,
      onSelectMenuItem: _ctx.selectBrand,
      onHide: _ctx.hideModal
    }, null, 8, ["data", "opened", "title", "loading", "brands", "current-brand", "onSubmit", "onSelectMenuItem", "onHide"]),
    _createVNode(_component_DevicesTable, {
      sort: _ctx.sortConfig,
      "brands-list": _ctx.brandsList,
      "devices-list": _ctx.devicesList,
      "total-rows-count": _ctx.totalDevicesCount,
      "table-title": 'Devices',
      "view-configuration": _ctx.viewConfiguration,
      onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
      onChangeTab: _ctx.changeTabHandler,
      onOpenAddForm: _ctx.openForm,
      onDeleteDeviceAction: _ctx.deleteDeviceData,
      onEditDeviceAction: _ctx.editDeviceModal
    }, null, 8, ["sort", "brands-list", "devices-list", "total-rows-count", "view-configuration", "onChangeViewConfiguration", "onChangeTab", "onOpenAddForm", "onDeleteDeviceAction", "onEditDeviceAction"]),
    _createVNode(_component_DeleteDeviceDialog, {
      id: _ctx.editData.id,
      opened: _ctx.deleteDeviceDialogOpened,
      title: _ctx.editData.title,
      photo: _ctx.editData.icon,
      loading: _ctx.deleteUserLoading,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDeviceDialogOpened = false))
    }, null, 8, ["id", "opened", "title", "photo", "loading", "onConfirm"])
  ], 64))
}