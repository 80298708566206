<template>
  <DarkOverlay :shown="opened" />

  <div class="control-wrapper" :class="{ opened }">
    <DatePicker
      :parent-el="parentEl"
      :active-from="activeFrom"
      :active-to="activeTo"
      :multiple="multiple"
      :calendar-text="calendarText"
      :button-text="buttonText"
      @init="calendarInitHandler"
      @open="opened = true"
      @close="opened = false"
      @select="selectDateHandler"
    />
    <ShadowControl
      :id="id"
      :placeholder="calendarText"
      icon="calendar"
      :value="newDate"
      name="date"
      @click="show"
    />

    <div ref="parentDropdown" class="picker-parent">
      <svg-icon icon="angle" />
    </div>
  </div>
</template>

<script>
import DatePicker from "./DatePicker.vue";
import ShadowControl from "@/components/Form/ShadowControl";
import SvgIcon from "@/components/ui/SvgIcon";
import DarkOverlay from "@/components/ui/DarkOverlay";
import { useCalendar } from "@/mixins/useCalendar";
import moment from "moment";
// const default_text = "Choose period";
export default {
  name: "AppCalendar",
  components: {
    ShadowControl,
    SvgIcon,
    DarkOverlay,
    DatePicker
  },
  mixins: [useCalendar],
  props: {
    id: {
      type: String,
      default: "asds"
    },
    activeFrom: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    activeTo: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    calendarText: {
      type: String,
      default: "Choose period"
    }
  },
  emits: {
    selectDate: null
  },
  data() {
    return {
      newDate: ""
    };
  },
  methods: {
    selectDateHandler(data) {
      this.newDate = data.date;
      this.$emit("selectDate", { ...data.value });
    }
  }
};
</script>

<style lang="scss">
$inputWidth: 180px;

.picker-parent {
  position: absolute;
  height: $field-height;
  width: 100%;
  top: $field-height;

  .icon {
    top: 0;
    right: $inputWidth;
    position: absolute;
    transform: translateY(-100%);
    color: #fff;
    opacity: 0;
  }
}

.control-wrapper {
  position: relative;
  width: $inputWidth;
}

.control-wrapper.opened {
  z-index: $aboveOverlay;

  .shadow-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .picker-parent {
    .icon {
      opacity: 1;
    }
  }
}
</style>
