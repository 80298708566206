<template>
  <Dialog title="Logs" :opened="opened" size="lg" @hide="hide">
    <JsonViewer :value="content" :expand-depth="1" copyable />
    <AppButton type="secondary" size="xl" @click="hide">
      Confirm
    </AppButton>
  </Dialog>
</template>

<script lang="js">
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent } from "@vue/runtime-core";
import moment from "moment";
import JsonViewer from "vue-json-viewer";


export default defineComponent({
  name: "LogsDialog",
  components: {
    AppButton,
    Dialog,
    JsonViewer
  },
  inheritAttrs: false,
  props: {
    // disabled: { type: Boolean, default: true },
    content: {
      type: Object,
      required: true
    },
    size: {
      default: 'lg',
      type: String
    },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "" },
    canCancel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: {
    submit: null,
    hide: null,
    selectMenuItem: null
  },
  data() {
    return {
      parentTouched: false,
    };
  },
  computed: {
    editModalTitle() {
      return this.title;
    },
    getTimeNow() {
      return moment();
    }
  },
  watch: {
    editingData(data) {
      if (data) {
        this.editData = { ...data };
      }
    }
  },
  methods: {
    hide() {
      this.$emit("hide");
    }
  }
});
</script>

<style scoped></style>
